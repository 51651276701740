import React from "react";

const IconWithName = ({ icon, items }) => (
  <div className="icon-with-name-wrapper">
    <div className="icon-name-item icon-wrapper">
      <img src={icon} alt={items[0]} className="icon-name-icon" />
    </div>
    <ul className="icon-name-item">
      {items.map((item, i) => (
        <li
          className="icon-name-name mb-2 text-dark-blue text-sm-bold "
          key={i}
        >
          {item}
        </li>
      ))}
    </ul>
  </div>
);

export default IconWithName;
