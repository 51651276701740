import React from "react";

const ContactForm = ({
  values,
  handleChange,
  handleSubmit,
  emailChange,
  handleBlur,
  errors,
  errorMsg,
  canSubmit,
  btnText,
  showResponse,
  successful,
  children,
}) => (
  <div className="contact-form-wrapper bg-dark-blue p-5 mb-5">
    <p className="text-center default-title xl-txt text-white mb-md-5 mb-4">
      Contact Us
    </p>
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="row">
        <div className="col-md-6 form-group">
          <input
            placeholder="Name"
            type="text"
            className="form-item form-control"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className="form-label" htmlFor="name">
            Name
          </label>
          {errors.nameError && (
            <span className="error text-warning sm-txt">
              {errorMsg.nameErrorMsg}{" "}
            </span>
          )}
        </div>
        <div className="col-md-6 form-group">
          <input
            placeholder="Email"
            type="email"
            className="form-item form-control"
            name="email"
            value={values.email}
            onChange={emailChange}
            onBlur={handleBlur}
          />
          <label className="form-label" htmlFor="email">
            Email
          </label>
          {errors.emailError && (
            <span className="error text-warning sm-txt">
              {errorMsg.emailErrorMsg}{" "}
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group">
          <input
            placeholder="Phone Number(optional)"
            type="text"
            className="form-item form-control"
            name="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className="form-label" htmlFor="phone">
            Phone Number(
            <span className="optional-field text-white-dull">optional</span>)
          </label>
          {errors.phoneError && (
            <span className="error text-warning sm-txt">
              {errorMsg.phoneErrorMsg}{" "}
            </span>
          )}
        </div>
        <div className="col-md-6 form-group">
          <input
            placeholder="Project Type (eg.website, mobile app)"
            type="text"
            className="form-item form-control"
            name="project"
            value={values.project}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className="form-label" htmlFor="project">
            Project Type
          </label>
          {errors.projectError && (
            <span className="error text-warning sm-txt">
              {errorMsg.projectErrorMsg}{" "}
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 form-group">
          <textarea
            placeholder="Project Description"
            rows="3"
            className="form-item form-control description-input"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className="form-label description-label" htmlFor="description">
            Project Description
          </label>
          {errors.descriptionError && (
            <span className="error text-warning sm-txt">
              {errorMsg.descriptionErrorMsg}{" "}
            </span>
          )}
        </div>
      </div>
      <div className="row">{children}</div>
      <div className="form-group col-12 text-right">
        <button
          type="submit"
          disabled={!canSubmit}
          className="submit-btn text-sm-bold text-dark-blue"
        >
          {btnText}
        </button>
      </div>
    </form>
    {showResponse ? (
      successful ? (
        <p className="bg-success text-white w-100 text-center p-2">
          Your Message was successfully sent. Thank you for choosing Modtechy
        </p>
      ) : (
        <p className="bg-danger text-white p-4">
          Sorry! There was an error while sending your message. Please try
          again. If the error persists please email us directly info@modtechy.ke
        </p>
      )
    ) : (
      ""
    )}
  </div>
);

export default ContactForm;
