import React from "react";
import { BrowserRouter } from "react-router-dom";
import validator from "validator";

import Header from "../containers/header/Header";
import Home from "../containers/home/Home";
import Services from "../containers/services/Services";
import Portfolio from "../containers/portfolio/Portfolio";
import Contact from "../containers/contacts/Contact";

class App extends React.Component {
  state = {
    email: "",
    emailError: false,
    emailErrorMsg: "Enter A Valid Email Address"
  };

  handleChange = e => {
    const el = e.target;
    this.setState({ [el.name]: el.value });
    this.handleBlur(e);
  };

  handleBlur = e => {
    if (e.target.value.trim().length < 1) {
      this.setState({
        emailError: true,
        emailErrorMsg: "Your Email Address is required"
      });
    } else if (!validator.isEmail(e.target.value)) {
      this.setState({
        emailError: true,
        emailErrorMsg: "Enter A Valid Email Address"
      });
    } else {
      this.setState({ emailError: false });
    }
  };

  render() {
    const { emailError, emailErrorMsg, email } = this.state;
    return (
      <BrowserRouter>
        <Header />
        <Home
          email={email}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
        />
        <Services />
        <Portfolio />
        <Contact
          email={email}
          emailChange={this.handleChange}
          emailError={emailError}
          emailErrorMsg={emailErrorMsg}
        />
        <div className="copyright bg-dark-blue text-white text-center py-1">
          copyright &copy; modtechy
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
