import React from "react";

import PortfolioItem from "../../components/portfolio/PortfolioItem";
import abmolinks from "../../assets/images/projects/abmolinks.PNG";
import modtechy from "../../assets/images/projects/modtechy.PNG";
import dta from "../../assets/images/projects/dta.webp";

const portfolio = [
  {
    image: modtechy,
    name: "modtechy.ke",
    website: "#",
  },
  {
    image: abmolinks,
    name: "abmolinks.co.ke",
    website: "https://abmolinks.co.ke",
  },
  {
    image: dta,
    name: "dtafrica.co.ke",
    website: "https://dtafrica.co.ke",
  },
];

class Portfolio extends React.Component {
  render() {
    return (
      <div
        className="services-wrapper portfolio-wrapper default-container mx-auto"
        id="portfolio"
      >
        <p className="header default-title text-center text-dark-blue xl-txt">
          Our Work
        </p>
        <div className="portfolio-items mt-5">
          {portfolio.map((item, i) => (
            <PortfolioItem {...item} key={i} />
          ))}
        </div>
      </div>
    );
  }
}

export default Portfolio;
