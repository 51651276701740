import React from "react";
import validator from "validator";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

import ContactForm from "../../components/contacts/contactForm";
import ReachOut from "../../components/contacts/ReachOut";

class Contact extends React.Component {
  state = {
    name: "",
    nameError: false,
    nameErrorMsg: "Enter A Valid Name",
    phone: "",
    phoneError: false,
    phoneErrorMsg: "Enter A Valid Phone Number",
    project: "",
    projectError: false,
    projectErrorMsg: "Enter A Valid Name eg(website, mobile app)",
    description: "",
    descriptionError: false,
    descriptionErrorMsg: "A Description Is Required",
    canSubmit: false,
    submitText: "Send Message",
    showResponse: false,
    successful: false,
    captchaComplete: false,
  };


  handleChange = (e) => {
    const el = e.target;
    this.setState({ [el.name]: el.value });
    this.handleBlur(e);
    this.enableSubmit();
  };

  handleBlur = (e) => {
    const currentField = e.target.name;
    const currentFieldValue = e.target.value.trim();
    let invalid = false;
    if (
      currentField === "name" &&
      (!validator.isLength(currentFieldValue, { min: 3, max: 50 }) ||
        !currentFieldValue.match(/^\s*[A-Za-z]+(?:\s+[A-Za-z]+)*\s*$/))
    ) {
      invalid = true;
    } else if (
      currentField === "project" &&
      !validator.isLength(
        currentFieldValue,
        { min: 3, max: 50 } ||
          !currentFieldValue.match(/^\s*[A-Za-z]+(?:\s+[A-Za-z]+)*\s*$/)
      )
    ) {
      invalid = true;
    } else if (currentField === "phone") {
      if (currentFieldValue.length > 0) {
        if (!validator.isMobilePhone(currentFieldValue)) invalid = true;
      }
    } else if (
      currentField === "description" &&
      !validator.isLength(currentFieldValue, { min: 200, max: 1000 })
    ) {
      invalid = true;
      if (currentFieldValue.length > 1000) {
        this.setState({
          descriptionErrorMsg:
            "You have exceeded text limit. Consider sending a direct Email instead",
        });
      } else {
        this.setState({
          descriptionErrorMsg:
            "The description should have at least 200 characters",
        });
      }
    }
    this.setState({ [currentField + "Error"]: invalid });
    this.enableSubmit();
  };

  setCaptcha = (val) => {
    this.setState({ captchaComplete: !!val });
    this.enableSubmit();
  };

  enableSubmit = () => {
    const {
      name,
      project,
      description,
      nameError,
      phoneError,
      projectError,
      descriptionError,
      captchaComplete,
    } = this.state;
    const { email, emailError } = this.props;

    if (
      nameError ||
      phoneError ||
      projectError ||
      descriptionError ||
      emailError ||
      !captchaComplete
    ) {
      this.setState({ canSubmit: false });
      return 0;
    }
    if (!name || !project || !description || !email) {
      this.setState({ canSubmit: false });
      return 0;
    }
    this.setState({ canSubmit: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitText: "Sending..." });
    const { name, project, description, phone } = this.state;
    const { email } = this.props;
    const templateParams = {
      name,
      email,
      description,
      project,
      phone,
    };

    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    this.setState({ canSubmit: false });
    emailjs.init(publicKey);
    emailjs
      .send(serviceId, templateId, templateParams)
      .then((res) => {
        if (res.status === 200) {
          this.setState(
            {
              showResponse: true,
              submitText: "Send Message",
              successful: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  showResponse: false,
                });
              }, 7000);
            }
          );
        }
      })
      .catch(() => {
        this.setState(
          {
            showResponse: true,
            submitText: "Send Message",
            successful: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                showResponse: false,
              });
            }, 7000);
          }
        );
      });
  };

  render() {
    const {
      name,
      phone,
      project,
      description,
      nameErrorMsg,
      phoneErrorMsg,
      projectErrorMsg,
      descriptionErrorMsg,
      nameError,
      phoneError,
      projectError,
      descriptionError,
      canSubmit,
      submitText,
      showResponse,
      successful,
    } = this.state;
    const { emailChange, email, emailError, emailErrorMsg } = this.props;
    const values = { email, name, phone, project, description };
    const errors = {
      emailError,
      nameError,
      phoneError,
      projectError,
      descriptionError,
    };
    const errorMsg = {
      emailErrorMsg,
      nameErrorMsg,
      phoneErrorMsg,
      projectErrorMsg,
      descriptionErrorMsg,
    };

    return (
      <div className="contact-wrapper default-container mb-4" id="contact">
        <ContactForm
          values={values}
          errors={errors}
          errorMsg={errorMsg}
          emailChange={emailChange}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          canSubmit={canSubmit}
          btnText={submitText}
          showResponse={showResponse}
          successful={successful}
        >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE}
            render="explicit"
            onErrored={() => this.setCaptcha(false)}
            onChange={() => this.setCaptcha(true)}
          />
        </ContactForm>
        <ReachOut />
      </div>
    );
  }
}

export default Contact;
