import React from "react";

import LandingSVG from "../../assets/images/bg/SVG/design-svg.svg";
import Twitter from "../../assets/icons/twitter.svg";
import LinkedIn from "../../assets/icons/linkedin.svg";
import FaceBook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";

class LandingPage extends React.Component {
  render() {
    const { email, handleChange, handleBlur } = this.props;
    return (
      <div id="landing-wrapper" className="bg-dark-blue">
        <div className="wrapper-items-container">
          <div className="landing-text landing-item">
            <p className="landing-title text-white">
              <span className="line-1 default-title landing-title">
                We design and create{" "}
              </span>
              <span className="line-2 default-title landing-title">
                awesome Websites
              </span>
            </p>
            <div className="email-bar-top">
              <p className="default-mini-title text-white mb-2">
                Let's talk about your website
              </p>
              <div className="email-input-top-wrapper">
                <input
                  type="email"
                  name="email"
                  className="email-input-top"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <a
                  href="#contact"
                  className="link-to-form text-bold text-dark-blue"
                >
                  Email Us
                </a>
              </div>
            </div>
          </div>
          <div className="landing-svg-wrapper landing-item">
            <img
              src={LandingSVG}
              alt="Design SVG"
              className="img-fluid landing-svg"
            />
          </div>
        </div>
        <div className="landing-socials">
          <img
            src={Twitter}
            alt="Twitter"
            className="social-icons pointer"
            onClick={() =>
              window.open("https://twitter.com/modtechy", "_blank").focus()
            }
          />
          <img
            src={FaceBook}
            alt="Facebook"
            className="social-icons pointer"
            onClick={() =>
              window.open("https://www.facebook.com/modtechy", "_blank").focus()
            }
          />
          <img
            src={LinkedIn}
            alt="LinkedIn"
            className="social-icons pointer"
            onClick={() =>
              window
                .open("https://www.linkedin.com/company/modtechy/", "_blank")
                .focus()
            }
          />
          <img
            src={Instagram}
            alt="Instagram"
            className="social-icons pointer"
            onClick={() =>
              window
                .open("https://www.instagram.com/modtechy", "_blank")
                .focus()
            }
          />
        </div>
      </div>
    );
  }
}
export default LandingPage;
