import React from "react";
import ServiceItem from "../../components/services/ServiceItem";

import webDes from "../../assets/icons/web-dev.svg";
import webDev from "../../assets/icons/dev-icon.svg";
import clientSup from "../../assets/icons/support-icon.svg";
import mobDev from "../../assets/icons/mobile-dev.svg";

const services = [
  {
    name: "Website and Mobile Design",
    description:
      "We know that the look of your website or app drives the first impression to your visitors. We create excellent out of the box designs with awesome user experiences that all your visitors or users will enjoy looking at and using.",
    icon: webDes
  },
  {
    name: "Web Development",
    description:
      "We develop all kinds of websites. From static to dynamic websites. Need a website for " +
      "displaying your products, items or work, blogging or a web based system to manage your work or" +
      " organization. We can do that for you here.",
    icon: webDev
  },
  {
    name: "Client Support",
    description:
      "We offer valuable advice on matters revolving around software development. We are always available " +
      "during our working hours and will respond to any emergencies that arise immediately.",
    icon: clientSup
  },
  {
    name: "Mobile Development",
    description: "Coming soon",
    icon: mobDev,
    noDesc: true
  }
];

class Services extends React.Component {
  render() {
    return (
      <div className="services-wrapper default-container" id="services">
        <p className="header default-title text-center text-dark-blue xl-txt">
          Our Services
        </p>
        <p className="title-mini-text text-center">
          We offer a variety of services revolving around software development,
          maintenance and updates.
        </p>
        <div className="service-items-wrapper mt-md-5 mt-2">
          {services.map((service, i) => (
            <ServiceItem {...service} key={i} />
          ))}
        </div>
      </div>
    );
  }
}

export default Services;
