import React from "react";

import Logo from "../../assets/icons/logo-white.svg";

class Header extends React.Component {
  state = {
    navVisible: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.scrollHeader);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHeader);
  }

  scrollHeader = () => {
    const services = document.querySelector("#services").offsetTop;
    const portfolio = document.querySelector("#portfolio").offsetTop;
    const contact = document.querySelector("#contact").offsetTop;
    const windowOffset = window.pageYOffset;
    const active = document.querySelector(".nav-link.active");
    active.classList.remove("active");
    if (windowOffset < services - 150) {
      document.querySelector(".nav-link.home").classList.add("active");
    } else if (windowOffset < portfolio - 150) {
      document.querySelector(".nav-link.services").classList.add("active");
    } else if (windowOffset < contact - 150) {
      document.querySelector(".nav-link.portfolio").classList.add("active");
    } else if (windowOffset >= contact - 150) {
      document.querySelector(".nav-link.contact").classList.add("active");
    } else {
      document.querySelector(".nav-link.home").classList.add("active");
    }
  };

  handleBarsClick = () => {
    if (window.innerWidth > 900) return 0;
    const nav = document.querySelector(".main-nav");
    const header = document.querySelector(".main-header");
    const bars = document.querySelector(".menu-burger");
    const { navVisible } = this.state;
    if (nav.classList.contains("show-nav")) {
      nav.classList.remove("show-nav");
      nav.classList.add("hide-nav");
      bars.classList.add("fa-bars");
      bars.classList.remove("fa-times");
      header.classList.remove("header-black");
    } else if (nav.classList.contains("hide-nav")) {
      nav.classList.add("show-nav");
      nav.classList.remove("hide-nav");
      bars.classList.add("fa-times");
      bars.classList.remove("fa-bars");
      header.classList.add("header-black");
    } else {
      nav.classList.add("show-nav");
      bars.classList.add("fa-times");
      header.classList.add("header-black");
    }
    this.setState({ navVisible: !navVisible });
    return 1;
  };

  handleLinkClick = e => {
    const active = document.querySelector(".nav-link.active");
    active.classList.remove("active");
    e.target.classList.add("active");
    this.handleBarsClick();
  };

  render() {
    return (
      <header className="main-header fixed-top bg-dark-blue">
        <div className="header-items-wrapper">
          <div className="logo-container header-item">
            <img src={Logo} alt="Modtechy" className="img-fluid logo" />
          </div>
          <span
            className="fas fa-bars menu-burger"
            onClick={this.handleBarsClick}
          />
          <nav className="main-nav header-item">
            <ul className="navbar-ul text-white">
              <li className="nav-item" name="home">
                <a
                  href="#home"
                  onClick={this.handleLinkClick}
                  className="nav-link active home"
                >
                  Home
                </a>
              </li>
              <li className="nav-item" name="services">
                <a
                  href="#services"
                  onClick={this.handleLinkClick}
                  className="nav-link services"
                >
                  Services
                </a>
              </li>
              <li className="nav-item" name="portfolio">
                <a
                  href="#portfolio"
                  onClick={this.handleLinkClick}
                  className="nav-link portfolio"
                >
                  Portfolio
                </a>
              </li>
              <li className="nav-item" name="contact">
                <a
                  href="#contact"
                  onClick={this.handleLinkClick}
                  className="nav-link contact"
                >
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
