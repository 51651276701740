import React, { Component } from "react";
import LandingPage from "../../components/landingPage/LandingPage";

class Home extends Component {
  render() {
    const { email, handleChange, handleBlur } = this.props;
    return (
      <div className="home-wrapper" id="home">
        <LandingPage
          email={email}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </div>
    );
  }
}

export default Home;
