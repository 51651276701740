import React from "react";

const PortfolioItem = ({ name, image, website }) => (
  <div
    id="services"
    className="service-item portfolio-item mb-4 text-center"
    onClick={() => {
      if (website && website !== "/") {
        window.open(website, "_blank");
      }
    }}
  >
    <div className="service-content text-center">
      <img src={image} alt={name} className="portfolio-item-image" />
    </div>
    <p className="project-name text-dark-blue text-sm-bold">{name}</p>
  </div>
);

export default PortfolioItem;
