import React from "react";

import email from "../../assets/icons/email.svg";
import ring from "../../assets/icons/vibrate.svg";
import IconWithName from "./IconWithName";
import Twitter from "../../assets/icons/twitter.svg";
import LinkedIn from "../../assets/icons/linkedin.svg";
import FaceBook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";

const contacts = [
  {
    icon: email,
    name: "email",
    items: ["info@modtechy.ke"],
  },
  {
    icon: ring,
    name: "ring",
    items: ["+254 113 983 830"],
  },
];

const socials = [
  {
    icon: Twitter,
    name: "Twitter",
    items: ["modtechy"],
  },
  {
    icon: FaceBook,
    name: "FaceBook",
    items: ["modtechy"],
  },
  {
    icon: Instagram,
    name: "Instagram",
    items: ["modtechy"],
  },
  {
    icon: LinkedIn,
    name: "LinkedIn",
    items: ["Modtechy Limited"],
  },
];

const ReachOut = () => (
  <div className="reach-out-wrapper py-4">
    <p className="reach-out-title text-center default-title xl-txt text-dark-blue mb-md-5 mb-4">
      Reach Out To Us
    </p>
    <div className="reach-out-items-wrapper">
      <div className="reach-out-items">
        {contacts.map((contact) => (
          <IconWithName {...contact} key={contact.name} />
        ))}
      </div>
      <div className="reach-out-items">
        {socials.map((contact) => (
          <IconWithName {...contact} key={contact.name} />
        ))}
      </div>
    </div>
  </div>
);

export default ReachOut;
