import React from "react";

const ServiceItem = ({ name, icon, description, noDesc }) => (
  <div id="services" className="service-item mb-4">
    {/* <div className="service-content service-image-icon-wrapper">
      <img src={icon} alt={name} className="service-image-icon" />
    </div> */}
    <div className="service-content service-text p-3">
      <p className="service-title text-dark-blue text-md-bold">{name}</p>
      <p
        className={`service-description sm-txt`}
      >
        {description}
      </p>
    </div>
  </div>
);

export default ServiceItem;
